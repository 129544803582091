import React from "react"
import PropTypes from "prop-types"
import {
  ExampleScreenshot,
  ExampleGridItem,
  ExampleHoverBackground,
  ExampleArrow,
  ExampleScreenshotWrapper,
} from "@components/resources/example-grid"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import Html from "@components/html"
import styled, { useTheme } from "styled-components"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Paragraph from "@components/elements/paragraph"

const KlipButton = styled.a`
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  background: ${props => (props.klip ? "#18aed8" : "#b8bfd6")};
  font-weight: 700;
  border-radius: 0.5rem;
  text-align: center;
  transition: filter 0.3s ease-in-out !important;

  &:hover {
    filter: brightness(0.9);
  }
`

export const GalleryGrid = ({ key, klips }) => {
  const { color } = useTheme()
  return (
    <Flex gap="2rem" padding="3rem" borderRadius="1rem" background="#FAFAFF">
      <Grid
        as="ul"
        gap="2rem"
        gapMd="1rem"
        margin="0"
        columns="repeat(3,1fr)"
        columnsMd="1fr 1fr"
        columnsSm="1fr"
        key={key}
      >
        {klips?.map(({ node: klip }, i) => {
          return (
            <Div as="li" margin="0" key={`gallery-${i}`}>
              <ExampleGridItem>
                <ExampleScreenshotWrapper to={`/gallery/klips/${klip.slug}`}>
                  <ExampleScreenshot kpi shadow={true}>
                    <Image file={klip.image} radius={0.75} showOverflow />
                    <ExampleHoverBackground radius={"0.5rem 0.5rem 0 0"}>
                      <ExampleArrow />
                    </ExampleHoverBackground>
                  </ExampleScreenshot>
                </ExampleScreenshotWrapper>
                <Flex height="100%" gap="1rem" padding="1.5rem">
                  <Heading letterSpacing="0" as="h3" fontSize="1.1rem">
                    <Anchor
                      color={color.blue600}
                      arrow
                      to={`/gallery/klips/${klip.slug}`}
                    >
                      {klip.name}
                    </Anchor>
                  </Heading>
                  <Div>
                    {klip.description_gallery ? (
                      <Paragraph fontSize="1rem">
                        {klip.description_gallery}
                      </Paragraph>
                    ) : (
                      <Html
                        styles={{
                          paragraph: {
                            fontSize: "1rem",
                          },
                        }}
                        html={klip.description}
                        parseElements
                      />
                    )}
                  </Div>
                  <Flex margin="auto 0 0" gap="0.5rem">
                    <Div margin="0 0 1.5rem">
                      <Paragraph
                        fontSize="1rem"
                        margin="0 0 0.5rem"
                        fontWeight={600}
                      >
                        Uses:
                      </Paragraph>
                      <Flex flexFlow="row" gap="0.5rem">
                        {klip.services?.map((service, i) =>
                          service.just_docs ? (
                            <Image
                              key={`service-${i}`}
                              file={service.logo}
                              height={32}
                              width={32}
                              objectFit="scale-down"
                            />
                          ) : (
                            <Anchor
                              key={`service-${i}`}
                              to={`/integrations/${service.slug}`}
                              fontSize="0.9rem"
                              color="#666"
                            >
                              <Image
                                file={service.logo}
                                height={32}
                                width={32}
                                objectFit="scale-down"
                              />
                            </Anchor>
                          )
                        )}
                      </Flex>
                    </Div>
                    <KlipButton as={Anchor} to={`/gallery/klips/${klip.slug}`}>
                      Learn More
                    </KlipButton>
                    <KlipButton href={klip.link} klip>
                      Get This Klip +
                    </KlipButton>
                  </Flex>
                </Flex>
              </ExampleGridItem>
            </Div>
          )
        })}
      </Grid>
    </Flex>
  )
}

GalleryGrid.propTypes = {
  key: PropTypes.string.isRequired,
  klips: PropTypes.array.isRequired,
}

export const ServiceGrid = ({ key, services }) => {
  const { color } = useTheme()
  return (
    <Flex gap="2rem" padding="3rem" borderRadius="1rem" background="#FAFAFF">
      <Grid
        as="ul"
        gap="2rem"
        gapMd="1rem"
        margin="0"
        columns="repeat(4,1fr)"
        columnsMd="1fr 1fr"
        columnsSm="1fr"
        key={key}
      >
        {services?.map(({ node: service }, i) => {
          return (
            <Div as="li" margin="0" key={`gallery-${i}`}>
              <ExampleGridItem>
                <Flex
                  alignItems="center"
                  height="100%"
                  gap="1rem"
                  padding="3rem 1.5rem"
                >
                  <Anchor
                    to={
                      service.just_docs
                        ? service.docs
                        : `/integrations/${service.slug}`
                    }
                  >
                    <Image
                      file={service.logo}
                      objectFit="scale-down"
                      height={64}
                      width={64}
                    />
                  </Anchor>
                  <Heading
                    textAlign="center"
                    letterSpacing="0"
                    as="h3"
                    fontSize="1.1rem"
                  >
                    <Anchor
                      color={color.blue600}
                      to={
                        service.just_docs
                          ? service.docs
                          : `/integrations/${service.slug}`
                      }
                    >
                      {service.name}
                    </Anchor>
                  </Heading>
                </Flex>
              </ExampleGridItem>
            </Div>
          )
        })}
      </Grid>
    </Flex>
  )
}

ServiceGrid.propTypes = {
  key: PropTypes.string.isRequired,
  services: PropTypes.array.isRequired,
}
