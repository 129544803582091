import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useSiteMetadata } from "@hooks/use-site-metadata"

const BreadcrumbSchema = ({ links }) => {
  const { siteUrl } = useSiteMetadata()

  function breadcrumb(link, i) {
    const url = link.url?.includes(siteUrl) ? link.url : `${siteUrl}${link.url}`
    return link.url
      ? {
          "@type": "ListItem",
          position: i + 1,
          name: link.text,
          item: url,
        }
      : {
          "@type": "ListItem",
          position: i + 1,
          name: link.text,
        }
  }

  breadcrumb.propTypes = {
    link: PropTypes.object.isRequired,
    i: PropTypes.number.isRequired,
  }

  let list = []
  links.map((link, i) => {
    list.push(breadcrumb(link, i))
  })

  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: list,
  }

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(schema)}`}</script>
    </Helmet>
  )
}

BreadcrumbSchema.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ),
}

export default BreadcrumbSchema
