import React from "react"
import styled from "styled-components"
import Flex from "@components/elements/flex"
import Html from "@components/html"
import PropTypes from "prop-types"
import { HtmlStylesType } from "@types/html-styles"

const StyledWrapper = styled(Flex)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem !important;
  }

  p + h2 {
    margin-top: 2rem;
  }

  p + h3 {
    margin-top: 1rem;
  }

  ul + h1,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6,
  ol + h1,
  ol + h2,
  ol + h3,
  ol + h4,
  ol + h5,
  ol + h6 {
    margin-top: 2rem !important;
  }

  h2 + h3 {
    margin-top: 0 !important;
  }

  ul,
  ol {
    margin-bottom: 0 !important;
  }

  .klipfolio-image-wrapper + h1,
  .klipfolio-image-wrapper + h2,
  .klipfolio-image-wrapper + h3,
  .klipfolio-image-wrapper + h4,
  .klipfolio-image-wrapper + h5,
  .klipfolio-image-wrapper + h6,
  .klipfolio-image-wrapper + p {
    margin-top: 1rem !important;
  }

  p + .klipfolio-image-wrapper {
    margin-top: 2rem;
  }

  .klipfolio-image-wrapper {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 0 !important;
  }

  p, ul, ol, li { 
    color: ${({ theme }) => theme.color.indigo700};
  }
`
export const HTMLWrapper = ({ cta, gap, html, images, styles, ...rest }) => (
  <StyledWrapper gap={gap} {...rest}>
    <Html cta={cta} html={html} parseElements images={images} styles={styles} />
  </StyledWrapper>
)

HTMLWrapper.defaultProps = {
  gap: "1rem",
  margin: "0 auto 6rem",
}

HTMLWrapper.propTypes = {
  cta: PropTypes.bool,
  gap: PropTypes.string,
  html: PropTypes.string.isRequired,
  images: PropTypes.array,
  styles: HtmlStylesType
}
